.section_hero {
  padding-top: 100px;
  padding-bottom: 100px;
}
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    margin-top: 60px;
    max-width: 450px;
    font-weight: 500;
    font-size: 18px;
    line-height: 38px;
    text-align: center;
    color: #828282;
    margin-bottom: 30px;
    line-height: 1.5;
  }
}

@media (max-width: 600px) {
  .section_hero {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .wrapper {
    svg {
      max-height: 300px;
    }
    p {
      margin-top: 0px;
      margin-bottom: 20px;
    }
  }
}
